<template>
  <div class="menu-stepper">
    <ol class="stepper-list">
      <li
        class="step-item"
        :class="value === i ? 'step-active' : ''"
        :key="i"
        v-for="(item, i) in items"
        @click="onClick(i)">
        <a>
          <span>{{ item.name }} </span></a>
      </li>
    </ol>
  </div>
</template>

<script>
export default {
  name: 'MenuStepper',
  props: {
    // items contains the Array of Objects
    items: Array,
    // value contains the index of stepper
    value: Number
  },

  data () {
    return {}
  },

  watch: {},

  mounted () {},

  methods: {
    onClick (index) {
      this.$emit('change', index)
    }
  }
}
</script>

<style lang="scss" scoped>
.menu-stepper {
  border-width: 0px;
  border-style: solid;
  box-sizing: border-box;
  outline: 0;
  font-size: 14px;
  line-height: 1.4;
  font-family: inherit;
  display: block;
  position: relative;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  color: inherit;
  background: none;

  .stepper-list {
    list-style: none;
    counter-reset: num;

    .step-item {
      padding: 11px;
      counter-increment: num;
      cursor: pointer;

      &::before {
        content: counter(num) '.';
        color: #888888;
        background-color: #ffffff;
        width: 24px;
        height: 22px;
        display: inline-block;
        text-align: center;
        margin-right: 11px;
      }

      &:hover {
        background-color: #ffffff;

        &::before {
          background-color: #c4c8f8;
        }
      }

      a {
        color: #7d7d7d;

        &:hover {
          color: var(--dmp-menu-stepper-hover-text-color);
        }
      }
    }

    .step-active {
      background-color: #ffffff;

      a {
        color: var(--dmp-entities-stepper-active-text-color);
      }

      &::before {
        background-color: #c4c8f8;
      }
    }
  }
}
</style>
