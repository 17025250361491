<template>
  <div class="entities-container">
    <div class="local-loader-container" v-if="loading">
      <div class="inava-loader"></div>
    </div>

    <div class="view-entity-header-container">
      <div class="action-btn">
        <button class="back-btn" @click="onBack">
          <span class="back-text"> Back</span>
        </button>
      </div>
      <div class="entity-info">
        <p class="info-title">
          {{ entityDetails[0]?.entityName ?? 'N/A' }}
        </p>
        <p class="date-updated">
          Last Updated:
          {{
            entityDetails[0]?.lastUpdated
              ? getLastUpdatedDate(entityDetails[0].lastUpdated)
              : 'N/A'
          }}
        </p>
        <p class="entity-id">
          SGA ID:
          {{ sgaId ?? 'N/A' }}
        </p>
        <p class="party-id">
          Request ID:
          {{ entityDetails[0]?.requestId ?? 'N/A' }}
        </p>
      </div>

      <div class="action-btn-pane">
        <button class="expand-btn" @click="onExpand">
          <span v-if="isExpandAll"> Collapse All </span>
          <span v-else> Expand All </span>
        </button>
      </div>
    </div>

    <!-- View Entity component -->
    <div class="view-entity-container">
      <EntityDetailsReview :isExpandAll="isExpandAll" :viewSGAId="viewSGAId"></EntityDetailsReview>
    </div>
  </div>
</template>

<script>
import EntityDetailsReview from '@/views/DataManagement/EntityDetailsReview.vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import moment from 'moment'
import {
  activeStepIndexName
} from '@/constant/data.js'

export default {
  name: 'ViewEntity',
  components: {
    EntityDetailsReview
  },

  props: {
    workType: String,
    viewSGAId: {
      type: String,
      required: false
    }
  },

  data () {
    return {
      isEnabled: false,
      sgaId: null,
      selected: 0,
      loading: false,
      isExpandAll: false,
      activeStepIndexName
    }
  },

  computed: {
    ...mapState('dmp', ['isAddEntitiesEnabled']),
    ...mapState('user', ['userDetails']),
    ...mapState('assignedEntities', ['entityDetails']),
    ...mapGetters('dmp', ['getEntityDetails'])
  },

  async created () {
    const { sgaId } = this.getEntityDetails
    this.sgaId = this.viewSGAId || sgaId
    if(!this.sgaId) {
      // If sgaID is not available then redirect to landing page
      this.$router.push({
        name: 'data-management-platform'
      })
    }
    await this.getEntityDetailsById({ sgaId: this.sgaId })
  },

  methods: {
    ...mapActions('assignedEntities', ['getEntityDetailsById']),
    ...mapActions('dmp', ['updateStepperForm']),

    getLastUpdatedDate (date) {
      return moment(date).format('DD/MM/YYYY')
    },

    onSelect (e) {
      this.selected = e.selected
    },

    onBack () {
      // Update Stepper only if it's from enrichment forms
      if(this.viewSGAId) this.updateStepperForm(this.activeStepIndexName.relationship)
      this.$router.go(-1)
    },

    onExpand () {
      this.isExpandAll = !this.isExpandAll
    }
  }
}
</script>
<style lang="scss" scoped>

.entities-container{
  max-width: 1450px;
  margin: 0 auto;
  background-color: var(--background-color);
}
.view-entity-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 32px 14px;

  .action-btn {
    flex: 0 0 281px;

    .back-btn {
      padding: 9px 15px;
      border-radius: 5px;
      border: 1px solid #888888;
      background-color: #888888;
      cursor: pointer;
      font-size: 18px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #ffffff;
    }
  }

  .entity-info {
    flex: 1 1 auto;

    .info-title {
      margin: 0;
      font-size: 30px;
      letter-spacing: 0px;
      color: var(--inava-primary-dark);
      font-weight: 600;
      display: inline-block;
      margin-right: 15px;
    }

    .date-updated,
    .entity-id,
    .party-id {
      display: inline-block;
      color: #1e1e1e;
      font-size: 18px;
      letter-spacing: 0.18px;
      padding-bottom: 5px;
      margin-right: 30px;
    }
  }

  .action-btn-pane {
    display: flex;
    align-items: center;
    flex: 0 1 auto;
    gap: 22px;

    .expand-btn {
      display: inline-block;
      padding: 9px 15px;
      text-align: center;
      color: #ffffff;
      border-radius: 4px;
      font-size: 18px;
      width: 130px;
      cursor: pointer;
      border: 1px solid #888888;
      background-color: #888888;
    }
  }
}
</style>
